html,
body {
  -webkit-tap-highlight-color: transparent;
}

div {
  box-sizing: border-box;
}

.color-inherit {
  color: inherit;
}

.no-shadow {
  box-shadow: none;
}

.pointer {
  cursor: pointer;
}

.nolist {
  list-style: none;
}

.actions-menu {
  color: $white;
}

.alert {
  background-color: #E8F4FD;
  padding: 6px 16px;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-radius: 4px;
  letter-spacing: 0.01071em;
  margin: 10px;

  .message {
    padding: 8px 0;
    font-size: 18px;
  }
  .alert-check {
    color:#359FF4;
    margin-right: 2px;
    font-size: 1.4rem;
  }
}

