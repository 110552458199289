@use '@angular/material' as mat;

@mixin clients-view-component-theme($theme) {
  $primary: map-get($theme, primary);

  mifosx-clients-view {
    .client-card {
      .header {
        background-color: mat.get-color-from-palette($primary, 500);
      }
    }
  }
}
