@use '@angular/material' as mat;

@mixin fixed-deposits-account-view-component-theme($theme) {
  $primary: map-get($theme, primary);
  mifosx-fixed-deposit-account-view {
    .fixed-deposits-account-card {
      .header {
        background-color: mat.get-color-from-palette($primary, 500);
      }
      .table-headers {
        background-color: mat.get-color-from-palette($primary, 500);
        color: mat.get-contrast-color-from-palette($primary, 500);
      }
    }
  }
}
