/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Import Default Theme for MifosX
@import "theme/custom/denim-yellowgreen";

// Global Imports
/* You can add global styles to this file, and also import other style files */
@import "assets/styles/helper";
